import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export type EntityArrayResponseType = HttpResponse<ICurrency[]>;

export interface ICurrency {
  id?: number;
  code?: string | null;
  name?: string | null;
  tenants?: ITenant[] | null;
}

export interface ITenant {
  id?: number;
  identifier?: string;
  name?: string | null;
  currencies?: ICurrency[] | null;
}

@Injectable({providedIn: 'root'})
export class CurrencyService {

  public resourceUrl = environment.backendUrl + '/invoicestore/app/api/currencies';

  constructor(protected http: HttpClient) {
  }

  getCurrenciesByTenant(tenantId: string): Observable<EntityArrayResponseType> {
    const httpParams = new HttpParams()
      .set('size', '10_000')
      .set('sort', 'code')
      .set('tenantId.equals', tenantId);

    return this.http.get<ICurrency[]>(this.resourceUrl, {params: httpParams, observe: 'response'});

  }

}
