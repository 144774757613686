<mat-card class="mat-elevation-z0 mat-card-filters">
  <div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="start center">
    <span fxFlex></span>
    <span fxFlex></span>
    <span fxFlex>Total:
      <span>
        {{ dataSource.totalOperations$ | async }}
      </span>
    </span>
    <span fxFlex></span>
    <span fxFlex></span>
  </div>
</mat-card>

<mat-drawer-container>

  <mat-drawer class="tenant-list" mode="side" opened>
    <div class="tenant-list-separator"></div>
    <div *ngFor="let item of checkboxData">
      <mat-checkbox
        color="primary"
        [value]="item.tenantIdentifier"
        [checked]="captureService.selectedTenantIdentifier === item.tenantIdentifier"
        (change)="onCheckboxChange(item.tenantIdentifier)"
        [matBadge]="item.backlogSize"
        [matBadgeHidden]="item.backlogSize == 0"
        matBadgeColor="warn"
        matBadgeSize="small"
        matBadgePosition="after"
        matBadgeOverlap="false">
        {{ item.tenantName }}
      </mat-checkbox>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <mat-card class="mat-elevation-z2 mat-card-table">
      <mat-table [dataSource]="dataSource" class="width-table">
        <ng-container matColumnDef="tenantName">
          <mat-header-cell *matHeaderCellDef>Tenant Name</mat-header-cell>
          <mat-cell *matCellDef="let row">{{
              row.processInstanceVariables.tenant_name
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <mat-header-cell *matHeaderCellDef>Entreprise</mat-header-cell>
          <mat-cell *matCellDef="let row">{{
              row.processInstanceVariables.invoice_customerParty_name
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef>Date Chargement</mat-header-cell>
          <mat-cell *matCellDef="let row">{{
              row.created | date: "dd/MM/yyyy H:mm"
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lockedBy">
          <mat-header-cell *matHeaderCellDef>Vérouillé par</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.assignee?.fullName }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="redirectToCaptureViewer(row.id,
          row.processInstanceVariables.invoice_invoiceStore_invoiceId,
          row.processInstanceVariables.tenant_identifier)"
        ></mat-row>
      </mat-table>

      <mat-paginator
        [length]="dataSource.totalOperations$ | async"
        [pageIndex]="0"
        [pageSize]="50"
        [pageSizeOptions]="[5, 10, 20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </mat-card>
  </mat-drawer-content>

</mat-drawer-container>
