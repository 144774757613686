import {AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {InvoiceService} from '../services/invoice.service';
import {Router} from '@angular/router';
import {CaptureDataSource} from './CaptureDataSource';
import {CaptureService} from '../services/capture-service';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {Subject, timer} from 'rxjs';
import {retry, takeUntil, tap} from 'rxjs/operators';


export interface CaptureBacklog {
  tenantIdentifier?: string;
  tenantName?: string;
  backlogSize?: string;
}

const pollingPeriod = 5000;

@Component({
  selector: 'app-capture-list',
  templateUrl: './capture-list.component.html',
  styleUrls: ['./capture-list.component.scss']
})
export class CaptureListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: CaptureDataSource;
  displayedColumns: string[] = [
    'tenantName',
    'companyName',
    'createdDate',
    'lockedBy'
  ];
  defaultPageSize = 50;
  stopPolling = new Subject();
  checkboxData: CaptureBacklog[] = [];
  invoiceIds: string[] = [];


  constructor(private invoiceService: InvoiceService,
              public captureService: CaptureService,
              private afMessaging: AngularFireMessaging,
              private router: Router,
              private ngZone: NgZone) {

    timer(1000, pollingPeriod).pipe(
      tap(() => this.refreshPage()),
      retry(),
      takeUntil(this.stopPolling)
    ).subscribe();

  }

  private refreshPage() {
    this.loadInvoicesPage();
    this.captureService.fetchCaptureBacklog();
  }

  ngOnDestroy() {
    this.stopPolling.next();
  }

  ngOnInit(): void {

    this.dataSource = new CaptureDataSource(this.invoiceService, this.captureService);
    this.captureService.captureBacklogSubject.subscribe((data) => {
      this.checkboxData = data;
    });

    if (!this.captureService.waitForUnclaim) {
      this.captureService.unclaimTask('');
    }
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe(() => this.loadInvoicesPage());
    this.afMessaging.messages.subscribe(
      message => this.ngZone.run(
        () => this.dataSource.getTasksByTenant(this.captureService.selectedTenantIdentifier, 0, this.defaultPageSize)
      )
    );
  }

  loadInvoicesPage(): void {
    this.dataSource.getTasksByTenant(this.captureService.selectedTenantIdentifier, this.paginator.pageIndex, this.paginator.pageSize);
    // this.updateInvoiceIdsList();
    // console.log('Updated invoice IDs:', this.invoiceIds);
  }

  redirectToCaptureViewer(id: any, invoiceId: string, tenantIdentifier: string) {
    this.captureService.currentTenantIdentifier = tenantIdentifier;
    this.router.navigate(['capture', id]);
  }

  // Function to handle checkbox change
  onCheckboxChange(value: any) {
    if (this.captureService.selectedTenantIdentifier === value) {
      // If the same checkbox is clicked again, set selectedValue to null
      this.captureService.selectedTenantIdentifier = null;
    } else {
      // Set selectedValue to the clicked checkbox's value
      this.captureService.selectedTenantIdentifier = value;
    }

    this.refreshPage();
  }

  updateInvoiceIdsList() {

  }
}
