import {parse} from 'date-fns';

export function amountsParser(str: string): number {
  str = str.replace(',', '.');
  const parts = str.split('.');
  if (parts.length === 1) {
    return Number(str);
  } else {
    const newStr = parts.slice(0, -1).join('') + '.' + parts.slice(-1);

    return Number(newStr);
  }

}

const regexp = /^[0-9]{1,2}[\/\-\.]{1}[0-9]{2}[\/\-\.]{1}[0-9]{2,4}$/g;

/**
 * parse a string value as date using a regex
 */
export function dateParser(dateStr: string) {
  // check if the parameter match the regex
  if (dateStr.match(regexp)) {
    // get the separator from the parameter
    const separator = !!dateStr[2].match(/[\/\.\-]$/) ? dateStr[2] : dateStr[1];
    const dateParts = dateStr.split(separator);
    // if the last part contains only 2 numbers than add 20 before them
    dateParts[2] = dateParts[2].length === 2 ? `20${dateParts[2]}` : dateParts[2];
    const formattedData = `dd${separator}MM${separator}yyyy`;
    // return the parsed date
    return parse(dateParts.join(separator), formattedData, new Date());
  } else {
    return null;
  }
}
