import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '../services/loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements AfterViewInit, OnDestroy {
  public showLoading = false;
  public loadingSubscription: Subscription;
  public spinnerOptions: ISpinner = {
    color: 'primary',
    mode: 'indeterminate',
    value: 50
  };

  constructor(private loader$: LoaderService) {
  }

  ngAfterViewInit(): void {
    this.loadingSubscription = this.loader$.loader$
      .pipe()
      .subscribe((status: boolean) => {
        this.showLoading = status;
      });
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}


interface ISpinner {
  color: string;
  mode: string;
  value: number;
}
