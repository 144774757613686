import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CaptureService} from '../services/capture-service';
import {AttachmentDetails} from '../model/attachment-details';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Subscription} from 'rxjs';
import {InvoiceService} from '../services/invoice.service';
import {ActivatedRoute, Params} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';

@Component({
  selector: 'app-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.scss'],
})
export class CaptureComponent implements OnInit {
  @ViewChild('documentViewer') documentViewer: ElementRef;
  private canvas: ElementRef;
  private invoiceStoreId: any;

  @ViewChild('canvas') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.canvas = content;
    }
  }

  private invoiceApiSubscription: Subscription;
  heightStyle: { height: string };
  isCanvas = false;

  /** Variables to handle attachment canvas and pdfViewer */
  public pdfsrc: string;
  private attachment: Blob;
  public attachmentDetails: AttachmentDetails;

  constructor(
    private captureService: CaptureService,
    private invoiceService: InvoiceService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.captureService.getTask(params.id);
      this.getTaskInvoiceId();
    });
    // init doc viewer to canvas mode
    this.captureService.canvasChosen.next(true);

    this.captureService.canvasChosen.subscribe((isCanvas) => {
      this.isCanvas = isCanvas;
    });
  }

  heightChanged(e) {
    this.heightStyle = {height: `${e}px`};
  }

  /**
   * Send request to API and gets invoice attachment binary.
   * @param invoiceId the invoice id.
   * @param callback callback function for retrieving request binary.
   */
  private getAttachmentBinaryFromAPI(
    invoiceId: string,
    callback = this.setAttachmentBinary.bind(this)
  ): void {
    const url = `${environment.backendUrl}/invoicestore/app/api/invoices/${invoiceId}/attachment-content`;
    const body = {attachmentType: 'original-file'};

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader(
      'Authorization',
      'bearer ' + this.authenticationService.getUserToken() + ''
    );

    xhr.onload = function() {
      if (this.status === 200) {
        callback(this.response);
      } else {
        console.error(xhr.statusText);
      }
    };

    xhr.send(JSON.stringify(body));
  }

  /**
   * getAttachmentDataFromAPI request callback to store attachment binary.
   * @param blob the attachment binary.
   */
  private setAttachmentBinary(blob: Blob): void {
    this.attachment = blob;
    const {mimeType} = this.attachmentDetails;

    if (this.isAttachmentImage(mimeType)) {
      this.captureService.isPdfSubject.next(false);
    } else {
      this.captureService.isPdfSubject.next(true);
      this.createPDFString(blob);
    }
  }

  /**
   * Check if the mimeType binary is an actual image help to determine if we use pdfViewer or the canvas.
   * @param mimeType the type of binary received from the API
   */
  private isAttachmentImage(mimeType: string): boolean {
    return (
      mimeType === 'image/png' ||
      mimeType === 'image/jpeg' ||
      mimeType === 'image/jpg'
    );
  }

  /**
   * Generate a blob url for ng2-pdf-viewer
   * @param attachment the attachment binary
   */
  private createPDFString(attachment: Blob): void {
    this.pdfsrc = URL.createObjectURL(attachment);
  }

  private getAttachmentDetailsFromApi(invoiceId: string) {
    this.invoiceApiSubscription = this.invoiceService
      .getAttachmentDetailsFromAPI(invoiceId, 'original-file')
      .subscribe((response) => {
        this.attachmentDetails = response[0];
      });
  }

  private getTaskInvoiceId() {
    this.captureService.task$.subscribe((response) => {
      this.handleResponse(response);
    });
  }

  private handleResponse(response: any) {
    this.invoiceStoreId = response.processInstanceVariables.invoice_invoiceStore_invoiceId;
    this.getAttachmentDetailsFromApi(this.invoiceStoreId);
    this.getAttachmentBinaryFromAPI(this.invoiceStoreId);
  }
}
