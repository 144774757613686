import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {InvoiceService} from '../services/invoice.service';
import {CaptureTask} from '../model/capture-task';
import {CaptureService} from '../services/capture-service';

export class CaptureDataSource implements DataSource<CaptureTask> {

  private totalOperations: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly totalOperations$: Observable<number> = this.totalOperations.asObservable();

  public captureTasks = new BehaviorSubject<CaptureTask[]>([]);
  public readonly captureTasks$ = this.captureTasks.asObservable();

  private invoiceServiceSubscription: Subscription;

  constructor(private invoiceService: InvoiceService,
              private captureService: CaptureService) {
  }

  defaultPageSize = 50;


  connect(collectionViewer: CollectionViewer): Observable<CaptureTask[]> {
    this.getTasksByTenant(this.captureService.selectedTenantIdentifier, 0, this.defaultPageSize);
    return this.captureTasks$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.captureTasks.complete();
    this.invoiceServiceSubscription.unsubscribe();
  }

  getTasksByTenant(tenantIdentifier: string, pageIndex: number, pageSize: number) {

    this.invoiceServiceSubscription = this.invoiceService.getTaskListFromAPI(null, tenantIdentifier, 'asc', pageIndex, pageSize)
      .pipe(catchError(() => of([])))
      .subscribe(tasks => {
        this.captureTasks.next(tasks.data);
        this.totalOperations.next(tasks.allTenantsCount);
      });

  }

}
