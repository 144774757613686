export const environment = {
  production: true,
  backendUrl: 'https://api-alpha.konta.tech',

  keycloakConfig: {
    realm: 'Konta',
    url: 'https://identity-alpha.konta.tech/auth',
    clientId: 'konta-web-ui'
  },

  firebase: {
    apiKey: 'AIzaSyDt39fquFWB4FpafslCXRzTVebCJVmz5wk',
    authDomain: 'konta-int.firebaseapp.com',
    databaseURL: 'https://konta-int.firebaseio.com',
    projectId: 'konta-int',
    storageBucket: 'konta-int.appspot.com',
    messagingSenderId: '895730838699',
    appId: '1:895730838699:web:7964bc3e73bb89a060dbee'
  },
  captureProcessDefKey: 'dataCapture'
};
