import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaptureService} from '../../services/capture-service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {adaptToAPI, MY_DATE_FORMATS} from '../../utils/formHepler';
import {Invoice} from '../../model/invoice';
import {Party} from '../../model/party';
import {InvoiceService} from '../../services/invoice.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {amountsCleaner, iceCleaner, referenceCleaner} from '../../utils/cleaners';
import {amountsParser, dateParser} from '../../utils/parsers';
import {catchError, debounceTime, take, takeUntil} from 'rxjs/operators';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {NgModel} from '@angular/forms';
import {MatRadioChange} from '@angular/material/radio';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {CurrencyService, ICurrency} from '../../services/currency.service';
import {WorkspacesService} from '../../services/workspaces.service';

export interface IOption {
  id?: string;
  name?: string;
}

@Component({
  selector: 'app-capture-form',
  templateUrl: './capture-form.component.html',
  styleUrls: ['./capture-form.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter},
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {useStrict: true},
    },
  ],
})
export class CaptureFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() focusedElement: string;
  @ViewChild('name') nameRef: ElementRef;
  @ViewChild('form') form: any;
  @ViewChild('iceRecipient') iceRecipient: NgModel;
  @ViewChild('iceSender') iceSender: NgModel;
  fields = new Map();
  invoice: Invoice;
  currentIndex: number;
  public taskId: string;
  private formId: string;
  invoiceId: string;
  legalId: string;
  currencies: IOption[];
  private workspaceId: any;
  dateInFuture: boolean;
  today = new Date();
  private invoiceTaskSubscription: Subscription;
  private routeParamSubscription: Subscription;
  private routeDateSubscription: Subscription;
  private subjectSelectionSubscription: Subscription;
  private iceSupplierCountSubscription: Subscription;

  canvas = true;
  currentPage = 'first';

  private readonly destroy$ = new Subject();
  isPdf: boolean;
  private submittingForm = false;

  constructor(
    private captureService: CaptureService,
    private invoiceService: InvoiceService,
    private currencyService: CurrencyService,
    private workspaceService: WorkspacesService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.submittingForm = false;
    this.routeParamSubscription = this.route.params.subscribe(
      (params: Params) => (this.taskId = params.id)
    );
    this.routeDateSubscription = this.route.data.subscribe((data) => {
      this.formId = data.formMetadata.id;
    });

    this.initInvoice();
    this.initializeFields();
    // set the current index to the first field
    this.currentIndex = 0;
    this.captureService.changeInput(this.fields.get(0).text);

    this.captureService.isPdfSubject.subscribe(value => this.isPdf = value);
  }

  /**
   * initialize the invoice object used in the form
   */
  initInvoice() {
    this.invoice = new Invoice();
    this.invoice.recipient_party = new Party();
    this.invoice.sender_party = new Party();

  }

  ngAfterViewInit(): void {

    // give focus to the first field
    this.nameRef.nativeElement.focus();

    this.invoiceTaskSubscription = this.captureService.task$.subscribe((data) => {
      if (data) {
        this.handleResponseTask(data);
      }
    });
    this.cdr.detectChanges();
  }

  /**
   * initialize each field of the form by it's index, name, label and type
   */
  initializeFields() {
    this.fields.set(0, new Field('recipientPartyName', 'Nom Destinataire', FormItemType.Text, ''));
    this.fields.set(1, new Field('recipientPartyLegalId', 'ICE Destinataire', FormItemType.Ice, ''));
    this.fields.set(2, new Field('invoiceNumber', 'N° de Facture', FormItemType.Reference, ''));
    this.fields.set(3, new Field('orderReference', 'Référence Commande', FormItemType.Reference, ''));
    this.fields.set(4, new Field('issueDate', 'Date Facture', FormItemType.Date, ''));
    this.fields.set(5, new Field('taxExclusiveAmount', 'Total HT', FormItemType.Number, ''));
    this.fields.set(6, new Field('taxAmount', 'Total Taxes', FormItemType.Number, ''));
    this.fields.set(7, new Field('payableAmount', 'Net à Payer', FormItemType.Number, ''));
    this.fields.set(8, new Field('senderPartyName', 'Nom Emetteur', FormItemType.Text, ''));
    this.fields.set(9, new Field('senderPartyLegalId', 'ICE Emetteur', FormItemType.Ice, ''));
    this.fields.set(10, new Field('payeeFinancialAccount', 'RIB Emetteur', FormItemType.Text, ''));
    this.fields.set(11, new Field('documentCurrencyCode', 'Devise', FormItemType.Text, ''));
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any) {
    this.unclaimTask(this.taskId).subscribe(() => {
      this.captureService.unclaimTask('');
    });
  }

  private handleResponseTask(data: any) {
    this.legalId = data.processInstanceVariables.invoice_customerParty_legalId;
    this.workspaceId = data.processInstanceVariables.workspace_id;

    this.getTenantCurrencyOptions();

    this.invoice.setFieldWithBrainPrediction(data.processInstanceVariables);
    this.invoiceId = data.processInstanceVariables.invoice_invoiceStore_invoiceId;

    this.subjectSelectionSubscription = this.captureService.subjectSelection.subscribe((item) => {
      let result: captureValue;
      const field = this.fields.get(this.currentIndex);
      if (this.isFormItemDate(field)) {
        const parsed = dateParser(item.text.replace(/ /g, ''));
        if (parsed) {
          result = parsed;
        }
        // if the field is 'issueDate', verify that the date is greater Than Today
        if (field.field === 'issueDate' && result < new Date()) {
          this.dateInFuture = true;
        } else {
          this.dateInFuture = false;
        }
      } else if (this.isFormItemNumber(field)) {
        const cleanedAmount = amountsCleaner(item.text);
        const parsedAmount = amountsParser(cleanedAmount);

        if (parsedAmount !== 0 && !isNaN(parsedAmount)) {
          result = parsedAmount;
        } else {
          result = '';
        }
      } else if (this.isFormItemReference(field)) {
        result = referenceCleaner(item.text);
      } else if (this.isFormItemIce(field)) {
        result = iceCleaner(item.text);
        if (field.field === 'recipientPartyLegalId') {
          if (result !== this.legalId) {
            this.iceRecipient.control.setErrors({error: ''});
          } else {
            this.iceRecipient.control.setErrors(null);
          }
        } else {
          this.iceSupplierCountSubscription = this.invoiceService.getSupplierCount(result).pipe(take(1)).subscribe(value => {
            if (value === 0) {
              this.iceSender.control.setErrors({error: ''});
            } else {
              this.iceSender.control.setErrors(null);
            }
          });
        }
      } else {
        result = item.text;
      }

      this.invoice.setFieldAtIndex(this.currentIndex, result);

      if (this.currentIndex < this.fields.size - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
      this.captureService.changeInput(this.fields.get(this.currentIndex).text);

      let nextElement = null;
      if (this.currentIndex !== 11) {
        this.form.nativeElement.querySelector(
          'input[name="' + this.fields.get(this.currentIndex).field + '"]'
        );
      } else {
        nextElement = this.form.nativeElement.querySelector(
          'mat-select[name="' + this.fields.get(this.currentIndex).field + '"]'
        );
      }

      if (nextElement !== undefined && nextElement !== null) {
        nextElement.focus();
      }
    });

    this.iceRecipient.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value !== this.legalId) {
          this.iceRecipient.control.setErrors({error: ''});
        } else {
          this.iceRecipient.control.setErrors(null);
        }
      });

    /*this.iceSender.valueChanges
      .pipe(takeUntil(this.destroy$),
        debounceTime(700))
      .subscribe(value => {
        console.log('here in the value changed part: ' + value);
        this.invoiceService.getSupplierCount(value).subscribe(result => {
          if (result === 0) {
            this.iceSender.control.setErrors({error: ''});
          } else {
            this.iceSender.control.setErrors(null);
          }
        });
      });*/

    this.iceRecipient.control.markAsTouched();

    this.iceSender.control.markAsTouched();
  }

  onSubmit() {
    this.submittingForm = true;
    this.invoiceService.submitInvoiceForm(
      this.captureService.currentTenantIdentifier,
      this.invoiceId,
      this.taskId,
      adaptToAPI(this.invoice),
      this.formId,
      'Enregistrer'
    ).subscribe(
        () => {
          // get the next task
          this.invoiceService
            .getTaskListFromAPI(null, this.captureService.selectedTenantIdentifier, 'asc', 0, 1)
            .pipe(catchError(() => of([])))
            .subscribe((invoices) => {
              if (invoices.total < 1) {
                this.router.navigate(['capture']);
              } else {
                const invoice = invoices.data[0];
                this.initInvoice();
                this.currentIndex = 0;
                this.captureService.changeInput(this.fields.get(this.currentIndex).text);
                this.router.navigate(['capture', invoice.id]);
              }
            });
        },
        () => {
        }
      );
  }

  /**
   * notify the viewer component the modification of the selected field
   */
  onFocus(index: number) {
    this.currentIndex = index;
    this.captureService.changeInput(this.fields.get(this.currentIndex).text);
  }

  isFormItemDate(formItem: Field): boolean {
    return formItem.type ? formItem.type === FormItemType.Date : false;
  }

  isFormItemNumber(formItem: Field): boolean {
    return formItem.type ? formItem.type === FormItemType.Number : false;
  }

  isFormItemReference(formItem: Field): boolean {
    return formItem.type ? formItem.type === FormItemType.Reference : false;
  }

  isFormItemIce(formItem: Field): boolean {
    return formItem.type ? formItem.type === FormItemType.Ice : false;
  }

  ngOnDestroy(): void {
    if (!this.submittingForm) {
      this.unclaimTask(this.taskId).subscribe(() => {
        this.captureService.unclaimTask('');
      });
    }

    if (this.invoiceTaskSubscription) {
      this.invoiceTaskSubscription.unsubscribe();
    }

    if (this.iceSupplierCountSubscription) { this.iceSupplierCountSubscription.unsubscribe(); }
    this.subjectSelectionSubscription.unsubscribe();

    this.destroy$.next();
    this.destroy$.complete();
  }

  unclaimTask(taskId: string): Observable<any> {
    return this.invoiceService.unclaimTaskFromAPI(taskId);
  }
  onViewerChanged($event: MatRadioChange) {
    this.captureService.canvasChosen.next($event.value);
  }

  onPageChanged($event: MatButtonToggleChange) {
    this.captureService.pageChanged.next($event.value);
  }

  private getTenantCurrencyOptions() {
    if (this.workspaceId !== undefined) {
      this.workspaceService.getWorkspace(this.workspaceId).subscribe(wp => {
        this.currencyService.getCurrenciesByTenant(wp.tenantId).subscribe(response => this.currencies = this.toFormOptions(response.body));
      });
    }
  }

  private toFormOptions(currencies: ICurrency[]): IOption[] {
    return currencies.map((currency): IOption => {
      return {
        id: currency.code + '',
        name: currency.code
      };
    });

  }
}

export class Field {
  constructor(
    public field: string,
    public text: string,
    public type: FormItemType,
    public value: any
  ) {
  }
}

enum FormItemType {
  Text = 'text',
  Date = 'date',
  Number = 'number',
  Reference = 'reference',
  Ice = 'ice',
}

type captureValue = string | number | Date;
