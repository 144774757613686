import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {Geometry} from '../../utils/geometry';
import {InvoiceToCapture, Vertex} from '../../model/invoice-to-capture';
import {CaptureService} from '../../services/capture-service';
import {Subscription} from 'rxjs';
import {InvoiceService} from '../../services/invoice.service';
import {HttpEventType} from '@angular/common/http';
import {Point} from '../../model/point';
import {Rectangle} from '../../model/rectangle';
import {ActivatedRoute, Params} from '@angular/router';
import {AttachmentDetails} from '../../model/attachment-details';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-capture-viewer',
  templateUrl: './capture-viewer.component.html',
  styleUrls: ['./capture-viewer.component.scss'],
})
export class CaptureViewerComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  invoiceId: string;
  invoiceStoreId: string;
  public pdfsrc: string;
  @ViewChild('canvas') private drawingCanvas: ElementRef;
  @ViewChild('bgCanvas') private bgCanvas: ElementRef;
  @ViewChild('viewer') private viewer: ElementRef;
  @Output() heightChanged = new EventEmitter<number>();

  // setting a width and height for the canvas
  tempRects: Rectangle[] = [];
  rects: Rectangle[] = [];
  drawingCtx: CanvasRenderingContext2D;
  bgCtx: CanvasRenderingContext2D;

  displayedInvoice: InvoiceToCapture;
  private drawingColor = 'rgba(150, 100, 255, 0.5)';
  private isMouseDown: boolean;

  private mouseDownPos: any;
  private borderingColor = 'blue';
  private attachment: Blob;

  tooltipStyle: { left: string; top: string; display: string };

  private naturalImageWidth: number;
  private naturalImageHeight: number;

  scaleX: number;
  scaleY: number;
  private naturalScaleWidth: number;
  private naturalScaleHeight: number;
  selectedInput: string;
  subscription: Subscription;
  subscriptionTaskById: Subscription;
  pageChangedSubscription: Subscription;

  public attachmentDetails: AttachmentDetails;
  private invoiceApiSubscription: Subscription;
  page = 'first';
  private attachmentType: string;

  constructor(
    public captureService: CaptureService,
    public invoiceService: InvoiceService,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.invoiceId = params.id;
    });

    this.captureService.pageChanged.next('first');

    this.pageChangedSubscription = this.captureService.pageChanged.subscribe(
      (value) => {
        this.page = value;

        if (this.page === 'first') {
          this.attachmentType = 'ocr-input-file';
        } else {
          if (
            this.displayedInvoice !== undefined &&
            this.displayedInvoice.pageFiles.last_page !== undefined
          ) {
            this.attachmentType = 'img_page_last';
          } else {
            this.attachmentType = 'ocr-input-file';
          }
        }
        this.getTaskInvoiceId(this.invoiceId);
      }
    );

    window.addEventListener('scroll', this.scroll, true);
  }

  scroll(): void {
    this.tooltipStyle = {left: '', top: '', display: 'none'};
  }

  ngOnChanges() {
    if (this.invoiceStoreId) {
      this.invoiceService
        .getOcrInformationByInvoiceId(this.invoiceStoreId)
        .subscribe((data) => {
          this.reloadInvoiceData(data.ocrResult);
        });
    }
  }

  private getTaskInvoiceId(taskId: string) {
    this.subscriptionTaskById = this.captureService
      .task$
      .subscribe((response) => {
        this.handleResponse(response);
      });
  }

  private handleResponse(res: any) {
    this.invoiceStoreId =
      res.processInstanceVariables.invoice_invoiceStore_invoiceId;

    if (this.invoiceStoreId) {
      this.getAttachmentDetailsFromApi(this.invoiceStoreId);
      this.invoiceService
        .getOcrInformationByInvoiceId(this.invoiceStoreId)
        .subscribe((ocrData) => {
          this.reloadInvoiceData(ocrData);
        });
    }
  }

  private reloadInvoiceData(data) {
    if (data) {
      this.displayedInvoice = JSON.parse(data.ocrResult);
    }

    if (this.attachmentType && this.invoiceStoreId) {
      this.invoiceService
        .downloadFile(this.invoiceStoreId, this.attachmentType)
        .pipe(
          filter((event) => event.type === HttpEventType.Response), // Filter only response events
        )
        .subscribe((blob) => {
          if (blob.type === HttpEventType.Response) {
            this.setAttachmentBinary(blob.body);
          } else {
            this.drawEmptyBackground(
              this.bgCtx,
              this.drawingCtx,
              'assets/img/placeholder.png'
            );
          }
        });
    }
  }

  public ngAfterViewInit() {
    // get the context
    const canvasEl: HTMLCanvasElement = this.drawingCanvas.nativeElement;
    this.drawingCtx = canvasEl.getContext('2d');

    const canvasElBg: HTMLCanvasElement = this.bgCanvas.nativeElement;
    this.bgCtx = canvasElBg.getContext('2d');

    if (this.invoiceStoreId) {
      this.invoiceService
        .downloadFile(this.invoiceStoreId, this.attachmentType)
        .pipe(
          filter((event) => event.type === HttpEventType.Response), // Filter only response events
        )
        .subscribe((blob) => {
          if (blob.type === HttpEventType.Response) {
            this.setAttachmentBinary(blob.body);
          } else {
            this.drawEmptyBackground(
              this.bgCtx,
              this.drawingCtx,
              'assets/img/placeholder.png'
            );
          }
        });
    }

    this.subscription = this.captureService.focusedInput$.subscribe(
      (item) => (this.selectedInput = item)
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    if (this.isAttachmentImage(this.attachmentDetails.mimeType)) {
      this.drawDocumentBackground(this.attachment);
    }
  }

  private getAttachmentDetailsFromApi(invoiceId: string) {
    this.invoiceApiSubscription = this.invoiceService
      .getAttachmentDetailsFromAPI(invoiceId, this.attachmentType)
      .subscribe((response) => {
        this.attachmentDetails = response[0];
      });
  }

  private isAttachmentImage(mimeType: string): boolean {
    return (
      mimeType === 'image/png' ||
      mimeType === 'image/jpeg' ||
      mimeType === 'image/jpg'
    );
  }

  private setAttachmentBinary(blob: Blob): void {
    this.attachment = blob;
    const {mimeType} = this.attachmentDetails;

    this.isAttachmentImage(mimeType)
      ? this.drawDocumentBackground(blob)
      : this.createPDFString(blob);
  }

  drawDocumentBackground(blob: Blob) {
    const image = new Image();
    image.src = URL.createObjectURL(blob);
    const imageWidth = this.viewer.nativeElement.offsetWidth - 2;

    image.onload = () => {
      const imageHeight =
        (imageWidth * image.naturalHeight) / image.naturalWidth;
      this.bgCtx.canvas.width = imageWidth;
      this.bgCtx.canvas.height = imageHeight;
      this.drawingCtx.canvas.width = imageWidth;
      this.drawingCtx.canvas.height = imageHeight;
      this.bgCtx.drawImage(
        image,
        0,
        0,
        this.bgCtx.canvas.width,
        this.bgCtx.canvas.height
      );
      this.heightChanged.emit(imageHeight);
      this.naturalImageHeight = image.naturalHeight;
      this.naturalImageWidth = image.naturalWidth;
    };
  }

  drawEmptyBackground(bgCtx: any, drawingCtx: any, url: string) {
    const image = new Image();
    image.src = url;

    image.onload = () => {
      if (drawingCtx) {
        drawingCtx.canvas.width = image.naturalWidth;
        drawingCtx.canvas.height = image.naturalHeight;
      }

      if (bgCtx) {
        bgCtx.canvas.width = image.naturalWidth;
        bgCtx.canvas.height = image.naturalHeight;
        bgCtx.drawImage(image, 0, 0);
      }

      this.heightChanged.emit(image.naturalHeight);
    };
  }

  onMouseup(event) {
    this.isMouseDown = false;
    // draw the current rect drawn by the user and add it to the global rects
    const lastTempRect = this.tempRects[this.tempRects.length - 1];

    if (lastTempRect && this.displayedInvoice) {
      this.undoDrawingAllTempRects();

      this.drawRectangle(
        lastTempRect,
        this.drawingColor,
        this.rects,
        this.drawingCtx
      );

      lastTempRect.x = (lastTempRect.x / this.scaleX) * this.naturalScaleWidth;
      lastTempRect.y = (lastTempRect.y / this.scaleY) * this.naturalScaleHeight;
      lastTempRect.height = lastTempRect.height * this.naturalScaleHeight;
      lastTempRect.width = lastTempRect.width * this.naturalScaleWidth;

      const text =
        this.page === 'first'
          ? this.getContainedTextBlocks(lastTempRect, 1)
          : this.displayedInvoice.pageFiles.last_page !== undefined
            ? this.getContainedTextBlocks(lastTempRect, 'last_page')
            : this.getContainedTextBlocks(lastTempRect, 1);

      this.captureService.sendSelectedText(text);

      // clearing the temprects
      this.tempRects = [];
    }
  }

  onMousedown(e) {
    const {x, y} = this.getRealMousePosition(e.clientX, e.clientY);

    this.mouseDownPos = {x, y};
    this.isMouseDown = true;
  }

  // React
  getContainedTextBlocks(rect: any, pageFile: any) {
    return (
      this.displayedInvoice.pageFiles[pageFile].vertices
        .filter((vertex: Vertex) => {
          return Geometry.rectContainsPoint(vertex.gravityCenter, rect);
        })
        // tslint:disable-next-line:max-line-length
        .sort(
          (elem1: Vertex, elem2: Vertex) =>
            elem1.gravityCenter.x - elem2.gravityCenter.x
        )
        .map(({text}) => text)
        .join(' ')
        .trim()
    );
  }

  undoDrawingAllTempRects() {
    this.tempRects.forEach((rect) =>
      this.drawingCtx.clearRect(
        rect.x - this.drawingCtx.lineWidth,
        rect.y - this.drawingCtx.lineWidth,
        rect.width + 2 * this.drawingCtx.lineWidth,
        rect.height + 2 * this.drawingCtx.lineWidth
      )
    );
  }

  drawRectangle(
    rect: Rectangle,
    color: any,
    rects: Rectangle[],
    drawingCtx: any
  ) {
    drawingCtx.lineWidth = '2';
    drawingCtx.fillStyle = color;
    drawingCtx.fillRect(rect.x, rect.y, rect.width, rect.height);

    return rects.concat(rect);
  }

  getRealMousePosition(x: number, y: number): Point {
    const rect = (
      this.drawingCanvas.nativeElement as HTMLCanvasElement
    ).getBoundingClientRect();
    this.scaleX =
      (this.drawingCanvas.nativeElement as HTMLCanvasElement).width /
      rect.width;
    this.scaleY =
      (this.drawingCanvas.nativeElement as HTMLCanvasElement).height /
      rect.height;

    this.naturalScaleWidth = this.naturalImageWidth / rect.width;
    this.naturalScaleHeight = this.naturalImageHeight / rect.height;

    const px = (x - rect.left) * this.scaleX;
    const py = (y - rect.top) * this.scaleY;
    return {x: px, y: py};
  }

  onMousemove(e: MouseEvent) {
    this.displayAndMoveTooltip({x: e.pageX, y: e.pageY});
    if (this.isMouseDown) {
      const p = this.getRealMousePosition(e.clientX, e.clientY);
      this.undoDrawingLastTempRect();
      this.handleDrawRect(this.mouseDownPos, p);
    }
  }

  onMouseover(e: { pageX: number; pageY: number }) {
    this.displayAndMoveTooltip({x: e.pageX, y: e.pageY});
  }

  onMouseleave(e: { pageX: number; pageY: number }) {
    this.tooltipStyle = {left: '', top: '', display: 'none'};
  }

  undoDrawingLastTempRect() {
    const lastTempRect = this.tempRects[this.tempRects.length - 1];
    if (lastTempRect) {
      this.drawingCtx.clearRect(
        lastTempRect.x - this.drawingCtx.lineWidth,
        lastTempRect.y - this.drawingCtx.lineWidth,
        lastTempRect.width + 2 * this.drawingCtx.lineWidth,
        lastTempRect.height + 2 * this.drawingCtx.lineWidth
      );
      this.tempRects = this.tempRects.filter(
        (_, i) => i !== this.tempRects.length - 1
      );
    }
  }

  handleDrawRect(
    topLeft: { x: number; y: number },
    bottomRight: { x: number; y: number }
  ) {
    const width = bottomRight.x - topLeft.x;
    const height = bottomRight.y - topLeft.y;

    let rect = new Rectangle();

    if (width < 0 && height < 0) {
      rect = {
        ...bottomRight,
        width: Math.abs(width),
        height: Math.abs(height),
      };
    } else if (width > 0 && height > 0) {
      rect = {
        ...topLeft,
        width,
        height,
      };
    } else if (width < 0 && height > 0) {
      rect = {
        x: bottomRight.x,
        y: topLeft.y,
        width: Math.abs(width),
        height,
      };
    } else if (width > 0 && height < 0) {
      rect = {
        x: topLeft.x,
        y: bottomRight.y,
        width,
        height: Math.abs(height),
      };
    }
    this.drawTempRect(rect);
  }

  drawTempRect(rect: Rectangle) {
    this.drawingCtx.fillStyle = this.drawingColor;
    this.drawingCtx.fillRect(rect.x, rect.y, rect.width, rect.height);

    // adding border effect.
    this.drawingCtx.lineWidth = 2;
    this.drawingCtx.strokeStyle = this.borderingColor;
    this.drawingCtx.strokeRect(rect.x, rect.y, rect.width, rect.height);

    this.tempRects.push(rect);
  }

  displayAndMoveTooltip(position: { x: number; y: number }) {
    const {x, y} = position;

    this.tooltipStyle = {
      left: `${x}px`,
      top: `${y - 30}px`,
      display: 'block',
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionTaskById.unsubscribe();
    this.pageChangedSubscription.unsubscribe();
  }

  private createPDFString(attachment: Blob): void {
    this.pdfsrc = URL.createObjectURL(attachment);
  }
}
