import {Point} from '../model/point';

export class Geometry {

  public static scaleRectangle(rect, srcRes, distRes) {
    const x = (rect.x / srcRes.width) * distRes.width;
    const y = (rect.y / srcRes.height) * distRes.height;
    const width = (rect.width / srcRes.width) * distRes.width;
    const height = (rect.height / srcRes.height) * distRes.height;
    return {
      x,
      y,
      width,
      height
    };
  }

  public static rectsOverlaps(a, b) {
    const dx = a.x < b.x ? a.x + a.width - b.x : b.x + b.width - a.x;
    if (dx < 0) {
      return false;
    }
    const dy = a.y < b.y ? a.y + a.height - b.y : b.y + b.height - a.y;
    return dy >= 0;
  }

  /**
   * checks if the point is inside the rectangle
   */
  public static rectContainsPoint(point: Point, rect) {
    return (point.x > rect.x &&
      point.x < rect.x + rect.width &&
      point.y > rect.y && point.y < rect.y + rect.height);
  }

  public static rectsContained(a, b) {
    return (
      b.x + b.width <= a.x + a.width &&
      b.x >= a.x &&
      b.y >= a.y &&
      b.y + b.height <= a.y + a.height
    );
  }
}
